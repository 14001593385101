"use client";
import { Calendar, StarPlanSubscription } from "@submix/component-library";
import classnames from "classnames";
import Link from "next/link";

const navigationLinkClass = `min-w-fit text-invert-primary text-sm font-semibold items-center py-4 flex gap-2.5 !rounded-none !justify-start !px-3`;

export type MobileNavigationProps = {
  translation: (key: string) => string;
  basePath: string;
  pathname: string;
};

export const Mobile = ({
  translation,
  basePath,
  pathname,
}: MobileNavigationProps) => {
  return (
    <nav className="flex h-[52px] overflow-x-scroll bg-primary-alt px-3 md:px-7 lg:hidden">
      <Link
        className={classnames(navigationLinkClass, {
          " border-b-[3px] border-b-neutral-900": pathname.includes("sessions"),
        })}
        href={`${basePath}/sessions`}
      >
        <Calendar height={16} width={16} />
        {translation("upcoming_sessions")}
      </Link>
      <Link
        className={classnames(navigationLinkClass, {
          " border-b-[3px] border-b-neutral-900":
            pathname.includes("subscription"),
        })}
        href={`${basePath}/account/subscription`}
      >
        <StarPlanSubscription className="max-h-4 max-w-4" />
        {translation("subscription_plan")}
      </Link>
    </nav>
  );
};
