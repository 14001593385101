import { BaseSvgProps } from "./types";
const SvgStarPlanSubscription = ({
  width,
  height,
  stroke = "#000",
  className,
  strokeWidth = 1.5,
}: BaseSvgProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    width={width || 24}
    height={height || 24}
    className={className}
  >
    <path
      d="m12.426 6.479 1.346 2.717a.431.431 0 0 0 .347.254l2.97.44a.457.457 0 0 1 .255.787L15.201 12.8a.449.449 0 0 0-.135.406l.516 2.988a.466.466 0 0 1-.677.491l-2.675-1.413a.5.5 0 0 0-.44 0l-2.674 1.413a.466.466 0 0 1-.677-.49l.516-3.022a.449.449 0 0 0-.135-.407l-2.167-2.09a.457.457 0 0 1 .28-.787l2.97-.44a.432.432 0 0 0 .347-.254l1.346-2.717a.456.456 0 0 1 .83 0Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 22.404c5.746 0 10.404-4.658 10.404-10.404 0-5.746-4.658-10.404-10.404-10.404C6.254 1.596 1.596 6.254 1.596 12c0 5.746 4.658 10.404 10.404 10.404Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgStarPlanSubscription;
