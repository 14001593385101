import { BaseSvgProps } from "./types";
const SvgCalendar = ({
  width,
  height,
  stroke = "#000",
  className,
  strokeWidth = 1.5,
}: BaseSvgProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    width={width || 24}
    height={height || 24}
    className={className}
  >
    <path
      d="M3.185 4.788a1.603 1.603 0 0 0-1.602 1.603v14.423a1.602 1.602 0 0 0 1.602 1.603h17.63a1.603 1.603 0 0 0 1.602-1.603V6.391a1.602 1.602 0 0 0-1.603-1.603H17.61m-16.026 6.41h20.834M6.391 1.584v6.41m11.218-6.41v6.41M6.391 4.788h8.013"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgCalendar;
