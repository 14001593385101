"use client";

import { Button, LogoutLeaveExternal } from "@submix/component-library";
import { signOut } from "next-auth/react";
import React from "react";
import classnames from "classnames";

const LogOutButton = ({
  className,
  basePath,
}: {
  className?: string;
  basePath: string;
}) => {
  const handleLogout = () => {
    signOut({ callbackUrl: `${basePath}/auth/login` });
  };
  return (
    <Button
      onClick={handleLogout}
      variant="secondary"
      color="black"
      size="small"
      className={classnames(className)}
      leftIcon={<LogoutLeaveExternal width={14} height={14} />}
    >
      Log Out
    </Button>
  );
};

export default LogOutButton;
