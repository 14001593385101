"use client";
import {
  Button,
  Calendar,
  StarPlanSubscription,
} from "@submix/component-library";
import classnames from "classnames";
import Link from "next/link";

const navigationLinkClass = `${Button.classes.baseClassName}${Button.classes.classNames.tertiary}${Button.classes.classNames.tertiary_black}${Button.classes.classNames.small} py-4 flex gap-7 !rounded-none !justify-start border-l-[3px] !px-7`;

export type DesktopNavigationProps = {
  translation: (key: string) => string;
  basePath: string;
  pathname: string;
};

export const Desktop = ({
  translation,
  basePath,
  pathname,
}: DesktopNavigationProps) => {
  return (
    <nav className="hidden pt-7 lg:block">
      <Link
        className={classnames(navigationLinkClass, {
          " border-l-neutral-900 ": pathname.includes("sessions"),
        })}
        href={`${basePath}/sessions`}
      >
        <Calendar height={16} width={16} />
        {translation("upcoming_sessions")}
      </Link>
      <Link
        className={classnames(navigationLinkClass, {
          " border-l-neutral-900": pathname.includes("subscription"),
        })}
        href={`${basePath}/account/subscription`}
      >
        <StarPlanSubscription className="max-h-4 max-w-4" />
        {translation("subscription_plan")}
      </Link>
    </nav>
  );
};
