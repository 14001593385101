"use client";
import React from "react";
import { Desktop } from "./Desktop";
import LogOutButton from "./LogOutButton";
import { Mobile } from "./Mobile";

export type NavigationProps = {
  loading?: boolean;
  onDark?: boolean;
  translation: (key: string) => string;
  className?: string;
  children: React.ReactNode;
  basePath: string;
  pathname: string;
};

const Navigation = ({
  translation,
  children,
  basePath,
  pathname,
}: NavigationProps) => {
  return (
    <section className="mt-[52px] bg-neutral-900 lg:px-7">
      <div className="relative mx-auto grid min-h-[50vh] max-w-screen-2xl grid-cols-6 gap-7 rounded-xl bg-primary lg:grid-cols-12">
        <div className="xl:col-span-3-alt col-span-6 rounded-l-xl bg-primary lg:col-span-3 lg:flex lg:flex-col lg:gap-7 lg:border-r lg:border-neutral-300">
          <Desktop
            basePath={basePath}
            pathname={pathname}
            translation={translation}
          />
          <Mobile
            basePath={basePath}
            pathname={pathname}
            translation={translation}
          ></Mobile>
          <LogOutButton
            basePath={basePath}
            className="absolute right-0 top-20 mx-3 justify-center sm:top-[84px] sm:mx-7 lg:top-[60px] lg:flex"
          />
        </div>
        <div className="col-span-6 px-3 pb-12 sm:px-7 lg:col-span-9 lg:pl-0 lg:pt-7">
          {children}
        </div>
      </div>
    </section>
  );
};

export default Navigation;
