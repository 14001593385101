import { BaseSvgProps } from "./types";
const SvgLogoutLeaveExternal = ({
  width,
  height,
  stroke = "#000",
  className,
  strokeWidth = 1.5,
}: BaseSvgProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    width={width || 24}
    height={height || 24}
    className={className}
  >
    <path
      d="M16.002 17.602v3.201a1.601 1.601 0 0 1-1.601 1.6H3.197a1.6 1.6 0 0 1-1.6-1.6V3.197a1.6 1.6 0 0 1 1.6-1.6H14.4a1.6 1.6 0 0 1 1.6 1.6v3.2M11.2 12h11.204m0 0-3.201-3.201m3.2 3.201-3.2 3.201"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgLogoutLeaveExternal;
